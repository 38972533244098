import bsCustomFileInput from 'bs-custom-file-input';
import { convertImageBlob } from '../lib/convert';
import { ImageMode, ImageModeUtil, OutputMode } from '../lib/enums';
import { saveAs } from 'file-saver';
import { useArrayState, useBooleanState } from 'react-use-object-state';
import { createRoot } from 'react-dom/client';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormLabel, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaCheck } from 'react-icons/fa';

function RowWithLabel({ labelText, labelFor, children }) {
    return (
        <Row className="mb-3">
            <Col md={3}>
                <FormLabel htmlFor={labelFor}>{labelText}</FormLabel>
            </Col>
            <Col md={9}>{children}</Col>
        </Row>
    );
}

function FileInputRow({ setFileList, numFiles, onChangeFile }) {
    const onChange = useCallback(
        (e) => {
            setFileList(Array.from(e.target.files));
            onChangeFile();
        },
        [setFileList]
    );
    return (
        <RowWithLabel labelText="Image file" labelFor="customFile">
            <div className="custom-file">
                <input
                    onChange={onChange}
                    type="file"
                    className="custom-file-input"
                    id="customFile"
                />
                <label className="custom-file-label" htmlFor="customFile">
                    {numFiles} file(s) selected.
                </label>
            </div>
        </RowWithLabel>
    );
}

function getDefaultFilename(filename) {
    return filename.split('.')[0];
}

function FileName({ name, upsert, index, filename }) {
    const onChange = useCallback(
        (e) => {
            upsert(e.target.value, index);
        },
        [upsert, index]
    );
    return (
        <Form.Control
            onChange={onChange}
            className="mb-2 custom-auto-height"
            type="text"
            name={'name' + index}
            value={name}
            placeholder={filename}
        />
    );
}

function FileNames({ names, upsert, fileList }) {
    if (names.length === 0) return null;
    return (
        <RowWithLabel labelText="File name(s)" labelFor="name0">
            {names.map((name, i) => (
                <FileName
                    index={i}
                    key={i}
                    name={name}
                    filename={getDefaultFilename(fileList[i].name)}
                    upsert={upsert}
                />
            ))}
        </RowWithLabel>
    );
}

const modeKeys = Object.keys(ImageMode).filter(
    (v) => isNaN(v) && !v.startsWith('ICF')
);

const ColorFormatOptions = memo(() => {
    return (
        <>
            {modeKeys.map((mode) => (
                <option key={mode} value={ImageMode[mode]}>
                    {mode}
                </option>
            ))}
        </>
    );
});

const ImageNameOptions = [
    { value: 0, label: 'Battery Full' },
    { value: 1, label: 'Battery Empty' },
    { value: 2, label: 'Battery 50% Charged' },
    { value: 3, label: 'Battery 75% Charged' },
    { value: 4, label: 'Green Lightning' },
    { value: 5, label: 'Black Lightning' },
    { value: 6, label: 'Fingerprint' },
    { value: 7, label: 'Ambient Light' },
    { value: 8, label: 'Green Check Mark' },
    { value: 9, label: 'Red Cross Mark' },
    { value: 10, label: 'Contact Support' },
    { value: 11, label: 'Gray Circle' },
    { value: 12, label: 'Right Arrow' },
    { value: 13, label: 'Red Line' },
    { value: 14, label: 'Blue Scanner' },
    { value: 15, label: 'Gray Scanner' },
    { value: 16, label: 'Light Blue Scanner' },
    { value: 17, label: 'Blue Lock' },
    { value: 18, label: 'Gray Lock' },
    { value: 19, label: 'Logo' },
    { value: 20, label: 'Frame' },
    { value: 21, label: 'User' },
    { value: 22, label: 'Warning' },
];

function ImageName({ imageName, setImageName }) {
    const onChange = useCallback((e) => {
        setImageName(parseInt(e.target.value));
    }, []);
    return (
        <RowWithLabel labelText="Image" labelFor="im">
            <Form.Control
                as="select"
                name="im"
                value={imageName}
                onChange={onChange}
                className="custom-auto-height"
            >
                {ImageNameOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Form.Control>
        </RowWithLabel>
    );
}

function ColorFormat({ colorFormat, setColorFormat }) {
    const onChange = useCallback((e) => {
        setColorFormat(parseInt(e.target.value));
    }, []);
    return (
        <RowWithLabel labelText="Color format" labelFor="cf">
            <Form.Control
                as="select"
                name="cf"
                value={colorFormat}
                onChange={onChange}
                className="custom-auto-height"
            >
                <ColorFormatOptions />
            </Form.Control>
            <p className="text-mute">
                <strong>Alpha byte</strong> Add a 8 bit Alpha value to every pixel
                <br />
                <strong>Chroma keyed</strong> Make LV_COLOR_TRANSP (lv_conf.h) pixels to
                transparent
            </p>
        </RowWithLabel>
    );
}

function OutputFormat({ colorFormat, outputFormat, setOutputFormat }) {
    const onChange = useCallback((e) => {
        setOutputFormat(e.target.value);
    }, []);
    const isTrueColor =
        colorFormat == ImageMode.CF_TRUE_COLOR_ALPHA ||
        colorFormat == ImageMode.CF_TRUE_COLOR ||
        colorFormat == ImageMode.CF_TRUE_COLOR_CHROMA ||
        colorFormat == ImageMode.CF_RGB565A8;
    return (
        <RowWithLabel labelText="Output format" labelFor="format">
            <Form.Control
                as="select"
                name="format"
                value={outputFormat}
                onChange={onChange}
                className="custom-auto-height"
            >
                <option value="c_array">C array</option>
                {!isTrueColor && <option value="bin">Binary</option>}
                {isTrueColor && (
                    <>
                        <option value="bin_332">Binary RGB332</option>
                        <option value="bin_565">Binary RGB565</option>
                        <option value="bin_565_swap">Binary RGB565 Swap</option>
                        <option value="bin_888">Binary RGB888</option>
                    </>
                )}
            </Form.Control>
        </RowWithLabel>
    );
}

function ExtraOptions({
                          canChangeEndian,
                          dither,
                          setDither,
                          bigEndian,
                          setBigEndian,
                      }) {
    const onDitherChange = useCallback(
        (e) => setDither(e.target.checked),
        [setDither]
    );
    const onEndianChange = useCallback(
        (e) => setBigEndian(e.target.checked),
        [setBigEndian]
    );
    return (
        <RowWithLabel labelFor={undefined} labelText="Options">
            <Form.Check
                custom
                value={dither}
                onChange={onDitherChange}
                type="checkbox"
                id={'dith-checkbox'}
                label="Dither images (can improve quality)"
            />
            <Form.Check
                custom
                disabled={!canChangeEndian}
                value={bigEndian}
                onChange={onEndianChange}
                type="checkbox"
                id={'endian-checkbox'}
                label="Output in big-endian format"
            />
        </RowWithLabel>
    );
}

function tryParsingImageData(url) {
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => resolve({ w: image.width, h: image.height });
        image.onerror = () => resolve(null);
        image.src = url;
    });
}

function padNumberWithZeros(number, width) {
    const paddedNumber = String(number);
    return paddedNumber.padStart(width, '0');
}

function App() {
    const [fileList, setFileList] = useState([]);
    const [colorFormat, setColorFormat] = useState(ImageMode.CF_INDEXED_2_BIT);
    const [imageNumber, setImageNumber] = useState(0);
    const [outputFormat, setOutputFormat] = useState('bin_565');
    const [code, setCode] = useState('');
    const [copied, setCopied] = useState(false);
    const [binaryData, setBinaryData] = useState(null); // New state variable
    const names = useArrayState([]);
    const dither = useBooleanState(false);
    const bigEndian = useBooleanState(false);
    useEffect(() => {
        names.setState(fileList.map((file) => getDefaultFilename(file.name)));
    }, [fileList]);
    const [isConverting, setIsConverting] = useState(false);
    const doConvert = useCallback(() => {
        setIsConverting(true);
        setCopied(false);
        setCode('');
        setBinaryData(null); // Reset binary data
        const performConversion = async () => {
            for (var i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                if (file) {
                    const reader = new FileReader();
                    await new Promise((resolve, reject) => {
                        const outputType = outputFormat;
                        let outputMode,
                            binaryFormat;
                        const requestedCf = colorFormat;
                        if (outputType == 'c_array') outputMode = OutputMode.C;
                        else {
                            outputMode = OutputMode.BIN;
                            const needBinaryFormat = ImageModeUtil.isTrueColor(requestedCf);
                            if (needBinaryFormat) {
                                const binFormatMap = {
                                    bin_332: ImageMode.ICF_TRUE_COLOR_ARGB8332,
                                    bin_565: ImageMode.ICF_TRUE_COLOR_ARGB8565,
                                    bin_565_swap: ImageMode.ICF_TRUE_COLOR_ARGB8565_RBSWAP,
                                    bin_888: ImageMode.ICF_TRUE_COLOR_ARGB8888,
                                };
                                binaryFormat = binFormatMap[outputType];
                                if (typeof binaryFormat == 'undefined')
                                    throw new Error('Binary format not found: ' + outputType);
                            }
                        }
                        async function doConvert(blob, overrideWidth, overrideHeight) {
                            let imageName = names.state[i];
                            if (imageName == '') {
                                imageName = getDefaultFilename(file.name);
                            }

                            const swapEndian =
                                outputMode == OutputMode.C && bigEndian.state;
                            const imageString = await convertImageBlob(blob, {
                                cf: requestedCf,
                                outName: imageName,
                                swapEndian: swapEndian,
                                outputFormat: outputMode,
                                binaryFormat,
                                overrideWidth,
                                overrideHeight,
                            });
                            console.log(imageString);
                            if (typeof imageString !== 'string') {
                                // If imageString is binary data
                                // Store binary data in state
                                setBinaryData({
                                    data: imageString,
                                    name: imageName,
                                    extension: 'bin',
                                });
                                resolve();
                            } else {
                                // Handle string output (e.g., C array)
                                setCode(imageString);
                                resolve();
                            }
                        }
                        if (ImageMode[colorFormat].startsWith('CF_RAW')) {
                            reader.readAsArrayBuffer(file);
                            reader.onload = async function (e) {
                                console.log('loaded');
                                const buf = e.target.result;
                                const blobUrl = URL.createObjectURL(new Blob([buf]));
                                const overrideInfo = await tryParsingImageData(blobUrl);
                                doConvert(
                                    new Uint8Array(buf),
                                    overrideInfo?.w,
                                    overrideInfo?.h
                                );
                            };
                        } else {
                            reader.onload = function (e) {
                                var image = new Image();
                                image.onload = function () {
                                    console.log('loaded');
                                    doConvert(image);
                                };

                                image.onerror = function (e) {
                                    reject(e);
                                };
                                image.src = e.target.result;
                            };
                            reader.readAsDataURL(file);
                        }
                    });
                }
            }
            setIsConverting(false);
        };
        performConversion().catch((e) => {
            console.error(e);
            window.alert(
                'An error occurred while converting, check the console for details'
            );
            setIsConverting(false);
        });
    }, [
        dither.state,
        bigEndian.state,
        setIsConverting,
        fileList,
        names,
        colorFormat,
        outputFormat,
    ]);

    const downloadBinary = useCallback(() => {
        if (binaryData) {
            const blob = new Blob([binaryData.data], {
                type: 'application/octet-stream',
            });
            saveAs(blob, `${binaryData.name}.${binaryData.extension}`);
        }
    }, [binaryData]);

    return (
        <>
            <Container>
                <Row>
                    <Col md={{ span: 9 }}>
                        <Form encType="multipart/form-data" name="img_conv">
                            <FileInputRow
                                setFileList={setFileList}
                                numFiles={fileList.length}
                                onChangeFile={() => {
                                    setCode('');
                                    setCopied(false);
                                    setBinaryData(null);
                                }}
                            />
                            <ImageName
                                imageName={imageNumber}
                                setImageName={setImageNumber}
                            />
                            <FileNames
                                fileList={fileList}
                                names={names.state}
                                upsert={names.upsertAt}
                            />
                            {/*<ColorFormat colorFormat={colorFormat} setColorFormat={setColorFormat}/>*/}
                            {/*<OutputFormat colorFormat={colorFormat} outputFormat={outputFormat} setOutputFormat={setOutputFormat}/>*/}
                            <ExtraOptions
                                canChangeEndian={outputFormat == 'c_array'}
                                dither={dither.state}
                                setDither={dither.setState}
                                bigEndian={bigEndian.state}
                                setBigEndian={bigEndian.setState}
                            />
                            <Form.Group>
                                <Button
                                    disabled={isConverting}
                                    onClick={doConvert}
                                    variant="primary"
                                    as="input"
                                    type="button"
                                    value="Convert"
                                    name="submit"
                                    id="convert-button"
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 9 }}>
                        {binaryData && (
                            <Button onClick={downloadBinary} variant="success">
                                Download Binary Image
                            </Button>
                        )}
                        {code.length > 0 && (
                            <CopyToClipboard
                                text={code}
                                onCopy={() => setCopied(true)}
                            >
                <pre>
                  {code}
                    <Button className="absolute flex flex-row  top-0 right-0 p-2">
                    {copied ? <FaCheck /> : <FaCopy />}
                  </Button>
                </pre>
                            </CopyToClipboard>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

$(document).ready(function () {
    bsCustomFileInput.init();
    createRoot(document.querySelector('.react-app-container')).render(<App />);
});

/* FIXME: temporary hack to fix setImmediate issue */
/* @ts-ignore */
window.setImmediate = (fn) => setTimeout(fn, 0);
